import React from 'react'
import SEO from '../../components/seo'
import SaleDetail from '../../components/GardenSale'
import styled from '@emotion/styled';

import { ImitationsBlockStyle, ButtonStyle, Container } from '../../utils/styles';

import { breakpoints } from '../../utils/styles'

import hero from '../../images/sales/gardening-sales-header.jpg';
import heroMobile from '../../images/sales/gardening-mobile-header.jpg';
import detailImg from '../../images/icons/copper-icon-reusable.png';

const DesktopImg = styled.img`
    width: 100%;
    display: none;
    @media (min-width: ${breakpoints.m}px){
      display: block;
    }
`

const MobileImg = styled.img`
    width: 100%;
    display: block;
    @media (min-width: ${breakpoints.m}px){
      display: none;
    }
`

const DetailImg = styled.img`
    width: 75px;
    margin: auto;
    margin-bottom: 40px;
    display: block;
`

const ImageBlock = (props) => (
  <>
    <DesktopImg alt={props.title} src={props.img} />
    <MobileImg alt={props.title} src={props.mobileImg} />
  </>
)

const ImitationsBlock = () => (
  <ImitationsBlockStyle className='imitations-block' style={{textAlign: 'center'}}>
    <h3>Help your garden grow</h3>
    <h1>SAVE 25% ON GARDENING ESSENTIALS</h1>
    <p>April showers don't bring May flowers... you do! Take care of your hands and posture with these Copper Compression products as you build your garden this spring! Save 25% on our Gardening Collection with code SPRING25 through Sunday</p>
    <ButtonStyle>
        <a href={"/sales/spring-equinox-sale?discount=SPRING25"}>Click to apply code</a>
    </ButtonStyle>
  </ImitationsBlockStyle>
)


const SalePage = () => {
  return (
    <Container>
      <SEO title="Help your garden grow Sale" description="April showers don't bring May flowers... you do! Take care of your hands and posture with these Copper Compression products as you build your garden this spring! Save 25% on our Gardening Collection with code SPRING25 through Sunday" keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />
      <ImageBlock img={hero} mobileImg={heroMobile} title="Daylight Savings" />
      <ImitationsBlock />
      <DetailImg src={detailImg} />
      <SaleDetail tag='25% Off' title='Gardening Collection'/>
    </Container>
  )
}

export default SalePage
